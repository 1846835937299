import React from 'react'
import { Button, Comment, Header, Icon } from "semantic-ui-react";

export default class CommentSection extends React.Component {
    componentDidMount() {
        const comments = document.getElementById("comment-section");
        comments.scrollTo(0, comments.scrollHeight);
    }

    componentDidUpdate() {
        const comments = document.getElementById("comment-section");
        comments.scrollTo(0, comments.scrollHeight);
    }

    render() {
        let conversations = window.localStorage.getItem("conversations");
        if (conversations === null) {
            return <>
                <Header textAlign='center'>
                    Welcome to the Chatroom!
                    <Header.Subheader>Here you can chat with our community of kind-hearted people.</Header.Subheader>
                    <Header.Subheader>As a heads up, always keep conversations civil!</Header.Subheader>
                </Header>
            </>;
        }

        conversations = JSON.parse(conversations);
        return <>
            <Button fluid color="blue" onClick={_ => {
                window.localStorage.removeItem("conversations")
                window.location.reload()
            }}>
                <Icon name="trash" />
                Clear conversation
            </Button>
            <Comment.Group>
                {conversations.map(conversation => <Comment>
                    <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/steve.jpg' />
                    <Comment.Content>
                        <Comment.Author as='a'>You</Comment.Author>
                        <Comment.Metadata>
                            <div>Today at 5:42PM</div>
                        </Comment.Metadata>
                        <Comment.Text>{conversation}</Comment.Text>
                    </Comment.Content>
                </Comment>)}
            </Comment.Group>
        </>;
    }
}
