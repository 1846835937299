import React, { useState } from 'react'
import { Button, Comment, Divider, Form, Grid, Header, Icon, Input, Placeholder, Menu, Modal, Segment, Sidebar, Transition, Table, Item, Label } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import CommentSection from './CommentSection';

const SidebarExampleVisible = function () {
	const [textToAnalyze, setTextToAnalyze] = useState("");
	const [analysisResult, setAnalysisResult] = useState({ status: "WAITING", data: null });
	const [currentMenu, setCurrentMenu] = useState("chat");
	const [visible, setVisible] = React.useState(false)


	return (
		<Grid columns={1}>
			<Grid.Column>
				<Sidebar.Pushable as={Segment}>
					<Sidebar
						as={Menu}
						animation='overlay'
						inverted
						onHide={() => setVisible(false)}
						vertical
						visible={visible}
					>
						<Menu.Item as='a' onClick={_ => setVisible(!visible)}>
							<b>Online Hate Speech Classification Model</b>
						</Menu.Item>
						<Menu.Item as='a' onClick={_ => setCurrentMenu("chat")} active={currentMenu === "chat"}>
							<Icon name='chat' />
							Chatroom
						</Menu.Item>
						<Menu.Item as='a' onClick={_ => setCurrentMenu("profile")} active={currentMenu === "profile"}>
							<Icon name='user' />
							Profile
						</Menu.Item>
					</Sidebar>

					<Sidebar.Pusher>
						<Segment basic style={{ 'min-height': "100vh" }}>
							{renderTab(currentMenu, visible, setVisible, textToAnalyze, setTextToAnalyze, analysisResult, setAnalysisResult)}
						</Segment>
					</Sidebar.Pusher>
				</Sidebar.Pushable>
			</Grid.Column>
		</Grid>
	)
}

const renderTab = function (tab, visible, setVisible, textToAnalyze, setTextToAnalyze, analysisResult, setAnalysisResult) {
	if (tab === "chat") {
		return <>
			<Header as='h3' onClick={_ => setVisible(!visible)}>
				<Icon name="chat" />
				Chatroom
			</Header>
			<Divider />
			<Segment style={{ height: "80vh", 'overflow-y': 'scroll', 'overflow-x': 'clip' }} id="comment-section">
				<CommentSection />
			</Segment>
			<Form onSubmit={_ => {
				if (analysisResult.status !== "WAITING" && analysisResult.status !== "RETRIEVED") {
					return <Transition
						animation="shake"
						duration={100}
						visible={true}
					/>;
				}

				let value = textToAnalyze
				value = value.trim();
				if (value === "") {
					setAnalysisResult({ status: "WAITING", data: null });
					return;
				}

				setTextToAnalyze("")
				setAnalysisResult({ status: "RETRIEVING", data: null });
				fetch("https://hatespeech.muqs.it/", {
					headers: { "Content-Length": value.length },
					method: "POST",
					body: value
				})
					.then(response => response.json())
					.then(response => {
						setAnalysisResult({ status: "RETRIEVED", data: response })
						if (response.result.length === 0) {
							pushConversation(value)
						}
					})
					.catch(e => {
						setAnalysisResult({ status: "FAILED", data: e })
					})
			}}>
				<Input
					icon='send'
					placeholder="Enter message"
					fluid
					value={textToAnalyze}
					onChange={e => {
						let value = e.target.value;
						setTextToAnalyze(value);
					}}
				>
					<Icon name='send' />
					<input autoCapitalize="off" autoComplete="off" spellCheck="false" />
				</Input>
			</Form>
			{displayAnalysisResultModal(analysisResult, setAnalysisResult)}
		</>
	}

	if (tab === "profile") {
		const flags = getFlags();
		const labelColors = {
			'toxic': 'violet',
			'severe_toxic': 'red',
			'obscene': 'yellow',
			'threat': 'olive',
			'insult': 'green',
			'identity_hate': 'orange'
		}
		const deleteButton = flags.length > 0 ? <Button color='blue' fluid onClick={_ => {
			window.localStorage.removeItem("flags");
			window.location.reload()
		}}>
			<Icon name='trash' />
			Clear Flagged Messages
		</Button> : null;
		return <>
			<Header as='h3' onClick={_ => setVisible(!visible)}>
				<Icon name='user' />
				Profile
			</Header>
			{deleteButton}
			<Divider />
			<Item.Group>
				<Item>
					<Item.Image size='tiny' src='https://react.semantic-ui.com/images/avatar/large/steve.jpg' />
					<Item.Content verticalAlign='middle'>
						<Item.Header>Steve Jobs</Item.Header>
						<Item.Description>
							<Placeholder>
								<Placeholder.Line />
								<Placeholder.Line />
							</Placeholder>
						</Item.Description>
					</Item.Content>
				</Item>
			</Item.Group>
			<Header as='h3'>
				<Icon name='clock outline' />
				<Header.Content>
					Recently-flagged Messages
					<Header.Subheader>A breakdown of recently-flagged messages of this user in descending order with reason.</Header.Subheader>
				</Header.Content>
			</Header>
			{flags.length > 0 ? <Table celled fixed singleLine compact>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Message</Table.HeaderCell>
						<Table.HeaderCell>Flagged For</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{flags.map(flag => <Table.Row>
						<Table.Cell>{flag.text}</Table.Cell>
						<Table.Cell>
							{flag.flags.map(reason => <Label color={labelColors[reason]}>{reason}</Label>)}
						</Table.Cell>
					</Table.Row>)}
				</Table.Body>
			</Table> : 'There are no messages to display.'}
		</>
	}

	return null;
}

const displayAnalysisResultModal = (analysisResult, setAnalysisResult) => {
	if (analysisResult.status !== "RETRIEVED" || analysisResult.data.result.length === 0) {
		return null;
	}

	return <Modal open={true}>
		<Modal.Header><Icon name="legal" />Chat Violation Notice</Modal.Header>
		<Modal.Content icon>
			<Modal.Description>
				<Header>Your Previous Message Breaks our Terms of Service</Header>
				<p>
					Please be polite while in the chatroom. Failure to do so may result in a penalty.
				</p>
				<Comment.Group>
					<Comment>
						<Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/steve.jpg' />
						<Comment.Content>
							<Comment.Author as='a'>You</Comment.Author>
							<Comment.Metadata>
								<div>Today at 5:42PM</div>
							</Comment.Metadata>
							<Comment.Text>{analysisResult.data.text}</Comment.Text>
						</Comment.Content>
					</Comment>
				</Comment.Group>
			</Modal.Description>
		</Modal.Content>
		<Modal.Actions>
			<Button
				content="Okay"
				labelPosition='right'
				icon='checkmark'
				positive
				onClick={_ => {
					pushFlag(analysisResult.data.text, analysisResult.data.result)
					setAnalysisResult({ status: "WAITING", data: null })
				}}
			/>
		</Modal.Actions>
	</Modal>
}

const pushConversation = text => {
	let conversations = window.localStorage.getItem("conversations");
	if (conversations === null) {
		conversations = [];
	} else {
		conversations = JSON.parse(conversations);
	}

	conversations.push(text);
	window.localStorage.setItem("conversations", JSON.stringify(conversations));
}

const getFlags = () => {
	let history = window.localStorage.getItem("flags");
	if (history === null) {
		return [];
	}
	return JSON.parse(history);
}

const pushFlag = (text, flags) => {
	let history = window.localStorage.getItem("flags");
	if (history === null) {
		history = [];
	} else {
		history = JSON.parse(history);
	}

	history.push({ text: text, flags: flags });
	window.localStorage.setItem("flags", JSON.stringify(history));
}

export default SidebarExampleVisible
